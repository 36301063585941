const pageHeight = document.body.clientHeight;
const pageWidth = document.body.clientWidth;
export const pxToDp =
  pageWidth <= 576 ? elemPx => (pageWidth * elemPx) / 390 : elemPx => elemPx;


export const colors = {
  primary1: '#4240B9',
  primary2: '#8751FB',
  primaryText: '#6347D9',
  secondary1: '#BAB9FF',
  secondary2: '#E8DDFF',
  purpleBtnDisabled1: '#E1E1E9',
  purpleBtnDisabled2: '#DDDAE4',
  purpleTileBg1: '#D1D2E7',
  purpleTileBg2: '#D6CFE9',
  white: '#FCFCFC',
  grayBg: '#F6F6F6',
  grayInput: '#EBEBF0',
  divider: '#E0E0E0',
  grayItem: '#D1D1D1',
  secondaryText: '#777985',
  black: '#333333',
  maskBg: 'rgba(0,0,0,0.2)',
  maskPicBlack: 'rgba(0,0,0,0.4)',
  maskPicWhite: 'rgba(255,255,255,0.75)',
  warning1: '#FF2E38',
  warning2: '#FD207D',
  themeColor: '#504EA6',   // abandoned,
  // new
  deepGrayText: '#999999',
  primarypurple_01: '#705AE0',
  primarypurple_02: '#8069F0',
  primarypurple_03: '#907AFF',
  primarypurple_04: '#9D8AFF',
  primarypurple_05: '#AA99FF',
  primarypurple_06: '#C5BAFA',
  primarypurple_07: '#F2F0FF',
  gray_tsp_01: '#333333',
  gray_tsp_02: '#949494',
  gray_tsp_03: '#C3C3C3',
  gray_tsp_04: '#D1D1D1',
  gray_tsp_05: '#E6E6E6',
  gray_tsp_06: '#F4F4F4',
  gray_tsp_07: '#F5F6FA',
  graypurple_01: '#5E5C70',
  graypurple_02: '#82818F',
  graypurple_03: '#82818F',
  graypurple_04: '#BDBCCC',
  graypurple_05: '#E0DFEB',
  graypurple_06: '#F8F7FA',
  mask_bg: 'rgba(0,0,0,0.2)',
  red1: '#FF2E38',
  red2: '#FD207D',
  red_02: '#EB564F',
  third_text: '#BCBDC3',
  gray08: '#F5F6FA',
};

export const fonts = {
  // 一般行高是字号+8
  // 标题1
  h1: {
    fontSize: pxToDp(24),
    fontWeight: '700',
    lineHeight: pxToDp(32)+'px'
  },
  // 标题2
  h2: {
    fontSize: pxToDp(20),
    fontWeight: '700',
    lineHeight: pxToDp(28)+'px'
  },
  // 标题3
  h3: {
    fontSize: pxToDp(18),
    fontWeight: '700',
    lineHeight: pxToDp(26)+'px'
  },
  // 正文1加粗
  t1bold: {
    fontSize: pxToDp(16),
    fontWeight: '600',
    lineHeight: pxToDp(24)+'px'
  },
  // 正文1
  t1: {
    fontSize: pxToDp(16),
    fontWeight: '400',
    lineHeight: pxToDp(24)+'px'
  },
  // 正文2加粗
  t2bold: {
    fontSize: pxToDp(14),
    fontWeight: '600',
    lineHeight: pxToDp(22)+'px'
  },
  // 正文2
  t2: {
    fontSize: pxToDp(14),
    fontWeight: '400',
    lineHeight: pxToDp(22)+'px'
  },
  caption1: {
    fontSize: pxToDp(12),
    fontWeight: '400',
    lineHeight: pxToDp(20)+'px'
  },
};
