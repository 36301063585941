export const planpageIcon = {
  purplecircle: <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <circle cx="5" cy="5" r="5" fill="url(#paint0_linear_9401_50567)" />
    <defs>
      <linearGradient id="paint0_linear_9401_50567" x1="0" y1="0" x2="10" y2="10" gradientUnits="userSpaceOnUse">
        <stop stopColor="#997AFF" />
        <stop offset="1" stopColor="#837AFF" />
      </linearGradient>
    </defs>
  </svg>,
  dragIcon: <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9" fill="none">
    <line x1="0.5" y1="0.5" x2="9.5" y2="0.5" stroke="#D1D1D1" stroke-linecap="round" />
    <line x1="0.5" y1="4.5" x2="9.5" y2="4.5" stroke="#D1D1D1" stroke-linecap="round" />
    <line x1="0.5" y1="8.5" x2="9.5" y2="8.5" stroke="#D1D1D1" stroke-linecap="round" />
  </svg>,
  hotelIcon: <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M1.45837 10.3541V7.58329C1.45837 7.36567 1.50998 7.15458 1.61319 6.95004C1.71639 6.7455 1.85923 6.57742 2.04171 6.4458V4.9583C2.04171 4.55073 2.18286 4.20579 2.46518 3.92347C2.7475 3.64116 3.09245 3.5 3.50003 3.5H5.97921C6.1916 3.5 6.38193 3.54132 6.55019 3.62396C6.71846 3.7066 6.86841 3.82083 7.00003 3.96667C7.13165 3.82083 7.28159 3.7066 7.44986 3.62396C7.61813 3.54132 7.80845 3.5 8.02085 3.5H10.5C10.9076 3.5 11.2526 3.64116 11.5349 3.92347C11.8172 4.20579 11.9583 4.55073 11.9583 4.9583V6.4458C12.1408 6.57742 12.2837 6.7455 12.3869 6.95004C12.4901 7.15458 12.5417 7.36567 12.5417 7.58329V10.3541C12.5417 10.4781 12.4997 10.582 12.4158 10.6658C12.3319 10.7497 12.228 10.7916 12.104 10.7916C11.98 10.7916 11.8761 10.7497 11.7923 10.6658C11.7086 10.582 11.6667 10.4781 11.6667 10.3541V9.62494H2.33336V10.3541C2.33336 10.4781 2.29141 10.582 2.20752 10.6658C2.12364 10.7497 2.01969 10.7916 1.89567 10.7916C1.77166 10.7916 1.66778 10.7497 1.58402 10.6658C1.50026 10.582 1.45837 10.4781 1.45837 10.3541ZM7.43751 6.12497H11.0834V4.9583C11.0834 4.79303 11.0275 4.65448 10.9157 4.54268C10.8038 4.43087 10.6653 4.37497 10.5 4.37497H8.02085C7.85557 4.37497 7.71703 4.43087 7.60522 4.54268C7.49342 4.65448 7.43751 4.79303 7.43751 4.9583V6.12497ZM2.91669 6.12497H6.56254V4.9583C6.56254 4.79303 6.50664 4.65448 6.39483 4.54268C6.28303 4.43087 6.14449 4.37497 5.97921 4.37497H3.50003C3.33475 4.37497 3.19621 4.43087 3.0844 4.54268C2.9726 4.65448 2.91669 4.79303 2.91669 4.9583V6.12497Z" fill="white" />
  </svg>,
  graycircle: <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <circle cx="5" cy="5" r="5" fill="#949494" />
  </svg>,
  eatIcon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M7.33333 5.23333C7.33333 5.60152 7.03486 5.9 6.66667 5.9C6.29848 5.9 6 5.60152 6 5.23333V1.66667C6 1.29848 5.70152 1 5.33333 1C4.96514 1 4.66667 1.29848 4.66667 1.66667V5.23333C4.66667 5.60152 4.36819 5.9 4 5.9C3.63181 5.9 3.33333 5.60152 3.33333 5.23333V1.66667C3.33333 1.29848 3.03486 1 2.66667 1C2.29848 1 2 1.29848 2 1.66667V5.9C2 7.384 3.10667 8.588 4.5 8.679V14.1667C4.5 14.6269 4.8731 15 5.33333 15C5.79357 15 6.16667 14.6269 6.16667 14.1667V8.679C7.56 8.588 8.66667 7.384 8.66667 5.9V1.66667C8.66667 1.29848 8.36819 1 8 1C7.63181 1 7.33333 1.29848 7.33333 1.66667V5.23333ZM10.6667 3.8V8.4C10.6667 8.95229 11.1144 9.4 11.6667 9.4H12.3333V14.1667C12.3333 14.6269 12.7064 15 13.1667 15C13.6269 15 14 14.6269 14 14.1667V1C12.16 1 10.6667 2.568 10.6667 3.8Z" fill="#333333" />
  </svg>,
  busIcon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_9401_50569)">
      <path d="M14.5663 4.10238H14.2674V2.6076C14.2674 2.11431 13.9064 1.53016 13.4652 1.30967C13.4652 1.30967 11.8757 0.514893 7.98923 0.514893C4.10276 0.514893 2.51338 1.30967 2.51338 1.30967C2.07213 1.53016 1.71113 2.11431 1.71113 2.6076V4.10238H1.41218C0.918898 4.10238 0.51532 4.50595 0.51532 4.99924V5.8961C0.51532 6.38938 0.918898 6.79296 1.41218 6.79296H1.71113V12.9216C1.71113 13.3101 1.9618 13.6418 2.30905 13.7657V14.2669C2.30905 14.9246 2.84716 15.4627 3.50488 15.4627C4.1626 15.4627 4.70071 14.9246 4.70071 14.2669V13.8185H11.2778V14.2669C11.2778 14.9246 11.8159 15.4627 12.4736 15.4627C13.1313 15.4627 13.6694 14.9246 13.6694 14.2669V13.7657C14.0167 13.6418 14.2674 13.3099 14.2674 12.9216V6.79296H14.5663C15.0596 6.79296 15.4632 6.38938 15.4632 5.8961V4.99924C15.4632 4.50597 15.0596 4.10238 14.5663 4.10238ZM4.99965 2.00967C4.99965 1.84525 5.13418 1.71072 5.2986 1.71072H10.6798C10.8443 1.71072 10.9788 1.84525 10.9788 2.00967V2.6076C10.9788 2.77202 10.8443 2.90655 10.6798 2.90655H5.2986C5.13418 2.90655 4.99965 2.77202 4.99965 2.6076V2.00967ZM3.65434 12.6227C3.24148 12.6227 2.90694 12.2878 2.90694 11.8753C2.90694 11.4627 3.24148 11.1279 3.65434 11.1279C4.06705 11.1279 4.40173 11.4627 4.40173 11.8753C4.40173 12.2878 4.06705 12.6227 3.65434 12.6227ZM12.3241 12.6227C11.9113 12.6227 11.5767 12.2878 11.5767 11.8753C11.5767 11.4627 11.9113 11.1279 12.3241 11.1279C12.737 11.1279 13.0715 11.4627 13.0715 11.8753C13.0715 12.2878 12.737 12.6227 12.3241 12.6227ZM13.0715 9.18467C13.0715 9.3491 12.937 9.48363 12.7726 9.48363H3.20591C3.04149 9.48363 2.90696 9.3491 2.90696 9.18467V4.40135C2.90696 4.23692 3.04149 4.10239 3.20591 4.10239H12.7726C12.937 4.10239 13.0715 4.23692 13.0715 4.40135V9.18467Z" fill="#333333" />
    </g>
    <defs>
      <clipPath id="clip0_9401_50569">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  markerBottomIcon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="6" viewBox="0 0 24 6" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 0.000899746C1.33803 0.000899746 2.17581 -0.0030238 3.01384 0.00551568C3.81591 0.0138244 4.71362 0.025595 5.49416 0.303243C6.33733 0.603279 6.89552 1.12788 7.45714 1.74526C7.86148 2.18931 8.65572 3.13674 9.04245 3.59417C9.35897 3.96922 9.97856 4.71054 10.3169 5.0692C10.7427 5.5204 11.2691 6 12.0002 6C12.7314 6 13.2575 5.5204 13.6831 5.06966C14.0214 4.71123 14.641 3.96945 14.9578 3.59464C15.344 3.1372 16.1383 2.18978 16.5429 1.74572C17.1055 1.12834 17.6627 0.603741 18.5056 0.303705C19.2864 0.026749 20.1843 0.014286 20.9859 0.00597727C21.8242 -0.00256221 22.662 0.00136134 23.5 0.00136134" fill="white" />
  </svg>,
  grayAddIcon: <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <g clip-path="url(#clip0_9401_50563)">
      <path d="M9.5 4.5H0.5C0.225 4.5 0 4.725 0 5C0 5.275 0.225 5.5 0.5 5.5H9.5C9.775 5.5 10 5.275 10 5C10 4.725 9.775 4.5 9.5 4.5Z" fill="#949494" />
      <path d="M5 0C4.725 0 4.5 0.225 4.5 0.5V9.5C4.5 9.775 4.725 10 5 10C5.275 10 5.5 9.775 5.5 9.5V0.5C5.5 0.225 5.275 0 5 0Z" fill="#949494" />
    </g>
    <defs>
      <clipPath id="clip0_9401_50563">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  walkIcon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M12.4205 7.17486L10.5098 6.45739L9.30698 4.23317C9.23609 4.16137 9.16521 4.0896 9.16521 4.01785C9.0947 3.94619 9.0947 3.94619 9.02381 3.87442C8.52836 3.3722 7.82095 3.30052 7.1841 3.58741L3.85813 4.73547C3.57515 4.80713 3.36289 5.09413 3.29211 5.38117L3.00904 8.03573C2.93836 8.4664 3.29211 8.89687 3.71665 8.96855H3.78742C4.21198 8.96855 4.56585 8.68165 4.56585 8.25116L4.77813 6.17042L6.05179 5.73988C5.83951 6.2421 5.62723 6.74436 5.48577 7.10316C5.20271 7.74887 5.2027 8.6098 5.55645 9.32738L3.36287 14.4934C3.15059 14.9953 3.36287 15.6412 3.85811 15.8565C4.07048 15.7848 4.21198 15.8565 4.35356 15.8565C4.77813 15.8565 5.13187 15.6412 5.27344 15.2108L6.90099 11.4799C7.89159 13.2735 9.0238 15.3543 9.0238 15.426C9.23608 15.7848 9.58992 16 9.94381 16C10.0852 16 10.2975 15.9281 10.4391 15.8564C10.9345 15.5695 11.1468 14.9238 10.8636 14.4215C10.7929 14.2781 9.09468 11.0494 7.96253 9.11209L8.95292 6.88788L9.30698 7.53358C9.37766 7.74882 9.58994 7.82059 9.73154 7.89237L11.925 8.7533C11.9959 8.7533 12.1373 8.82496 12.2082 8.82496C12.5619 8.82496 12.845 8.60972 12.9866 8.25113C13.0573 7.82048 12.845 7.31824 12.4204 7.17476M9.02381 3.15692C9.87292 3.15692 10.5807 2.43945 10.5807 1.57852C10.5807 0.717511 9.87294 0 9.02381 0C8.17481 0 7.4671 0.717458 7.4671 1.57852C7.4671 2.43947 8.17483 3.15692 9.02381 3.15692Z" fill="#333333" />
  </svg>,
  driveIcon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25" fill="none">
    <path d="M4.67927 15.4163V16.2016C4.67927 16.4464 4.5935 16.6545 4.42194 16.8259C4.25037 16.9973 4.04205 17.083 3.79696 17.083C3.55186 17.083 3.34385 16.9973 3.17292 16.8259C3.00198 16.6545 2.9165 16.4464 2.9165 16.2016V10.3234C2.9165 10.2368 2.92211 10.1503 2.93334 10.0638C2.94456 9.97722 2.96343 9.89433 2.98996 9.81511L4.48221 5.59582C4.58207 5.29574 4.76308 5.05192 5.02523 4.86436C5.28737 4.67679 5.58072 4.58301 5.90527 4.58301H14.0944C14.4189 4.58301 14.7123 4.67679 14.9744 4.86436C15.2366 5.05192 15.4176 5.29574 15.5174 5.59582L17.0097 9.81511C17.0362 9.89433 17.0551 9.97722 17.0663 10.0638C17.0775 10.1503 17.0831 10.2368 17.0831 10.3234V16.2016C17.0831 16.4464 16.9973 16.6545 16.8258 16.8259C16.6542 16.9973 16.4459 17.083 16.2008 17.083C15.9557 17.083 15.7477 16.9973 15.5768 16.8259C15.4058 16.6545 15.3204 16.4464 15.3204 16.2016V15.4163H4.67927ZM4.67288 8.8138H15.3268L14.3396 6.00128C14.3182 5.94785 14.2861 5.90645 14.2434 5.87707C14.2007 5.84768 14.1499 5.83299 14.0911 5.83299H5.90848C5.84972 5.83299 5.79897 5.84768 5.75623 5.87707C5.7135 5.90645 5.68144 5.94785 5.66007 6.00128L4.67288 8.8138ZM6.21965 13.2048C6.52287 13.2048 6.77998 13.0986 6.99098 12.8864C7.202 12.6741 7.3075 12.4164 7.3075 12.1132C7.3075 11.8099 7.20137 11.5528 6.98911 11.3418C6.77684 11.1308 6.5191 11.0253 6.21588 11.0253C5.91266 11.0253 5.65554 11.1314 5.44452 11.3437C5.23352 11.556 5.12802 11.8137 5.12802 12.1169C5.12802 12.4201 5.23416 12.6773 5.44642 12.8883C5.65868 13.0993 5.91643 13.2048 6.21965 13.2048ZM13.7838 13.2048C14.087 13.2048 14.3441 13.0986 14.5551 12.8864C14.7661 12.6741 14.8716 12.4164 14.8716 12.1132C14.8716 11.8099 14.7655 11.5528 14.5532 11.3418C14.3409 11.1308 14.0832 11.0253 13.78 11.0253C13.4768 11.0253 13.2197 11.1314 13.0086 11.3437C12.7976 11.556 12.6921 11.8137 12.6921 12.1169C12.6921 12.4201 12.7983 12.6773 13.0105 12.8883C13.2228 13.0993 13.4805 13.2048 13.7838 13.2048ZM4.16648 14.1663H15.8332V10.0638H4.16648V14.1663Z" fill="black" />
  </svg>
}
