import { useEffect, useState, useRef, useCallback } from 'react';
import moment, { secondSpan2Str } from 'src/utils/moment';
import { pxToDp, colors, fonts } from 'src/utils/styleKits';
import { geodecodeReq, planPathReq, getPlan } from 'src/api/api';
import { POINT_TYPE, TRAFFIC_TYPE, APP_SCHEME, IOS_LINK, ANDROID_LINK, OFFICIAL_LINK } from 'src/utils/defs';
import { point2json, refreshCovering } from 'src/utils/maputils';
import { planpageIcon } from 'src/utils/svg';
import { RenderEat, RenderHotel, RenderTrans } from './components';
import AMapLoader from '@amap/amap-jsapi-loader';
import { Digital } from "react-activity";
import "react-activity/dist/library.css";
import "./Planpage.css";
import { isAndroid, isIOS, isWeChat } from 'src/utils/browser';
// import VConsole from 'vconsole';
import axios from 'axios';
// TODO for temporary use
import { jsonToHump } from 'src/utils/convert';

// 858
export default function PlanPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(-1);
  const [plans, setPlans] = useState({});
  // 所有地点
  const [allSpotsOrSchedules, setAllSpotsOrSchedules] = useState([]);
  const [allTraffic, setAllTraffic] = useState([]);
  // 所有路线
  const [allPath, setAllPath] = useState([]);
  // 每天的路线距离
  const [allDistance, setAllDistance] = useState([]);
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // 地图
  const [zoom, setZoom] = useState(11);
  const [mapCenter, setMapCenter] = useState([116.397428, 39.90923]);
  // 行程中的每天酒店推荐中心
  // let accommodations 进入一次编辑行程页面，只有一次accommodations的变化，最初行程获取的推荐中心一直用
  const [accommodations, setAccommodations] = useState([]);
  // 每天的酒店选择
  const [hotels, setHotels] = useState([]);
  const [map, setMap] = useState(null);
  const [amap, setAmap] = useState(null);
  // const vconsole = new VConsole();

  useEffect(() => {
    const fetch = async () => {
      //! 获取参数
      var params = window.location.search;
      var planid = String(params.replace('?planid=', ''))
      setIsLoading(true);
      try {
        var res = await getPlan(planid);
        if (res.st == 30000) {
          res = await axios.get(`https://prerl.soroute.cn/share_api/plan/get_plan?plan_id=${planid}`)
          const newdata = res.data;
          jsonToHump(newdata);
          res = newdata;
        }
        if (res.st) {
          alert('获取行程失败');
          window.location.href = OFFICIAL_LINK
        }
        else {
          processPlanData(res.data);
          // to wait for the path requests finished
          setTimeout(() => {
            setIsLoading(false);
          }, 400);
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        window.location.href = OFFICIAL_LINK
      }
    }
    fetch();

  }, [])

  // why here?
  // 不能写在一个component里面，因为这样的话每次整个页面刷新都会导致component的刷新，每次component刷新就会导致高德重新加载地图，
  // 因为高德api的问题，加载太多就爆了，见https://zhuanlan.zhihu.com/p/532154901?utm_id=0
  useEffect(() => {
    if (isLoading == false) {
      // when isLoading is false, the 'sl-amap-container' div is rendered
      AMapLoader.load({
        key: "3b82d500902a4fe994ba37704c01a070",                     // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",              // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        // https://lbs.amap.com/api/javascript-api-v2/update
        plugins: ['AMap.MarkerCluster'],               // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        const _map = new AMap.Map("sl-amap-container", {
          // willReadFrequently is of no use
          willReadFrequently: true,
          viewMode: '2D', // 默认使用 2D 模式，如果希望使用带有俯仰角的 3D 模式，请设置 viewMode: '3D'
          zoom: zoom, // 初始化地图层级
          center: mapCenter, // 初始化地图中心点
          mapStyle: "amap://styles/9d4d24fbab75ec6fb90105d8e3fc27c6"
        });
        setAmap(AMap);
        setMap(_map);

        _refreshCovering(_map, AMap);
      }).catch(err => {
        console.log(err)
      });
    }
  }, [isLoading])

  const _refreshCovering = async (_map, _amap) => {
    if (!allSpotsOrSchedules || allSpotsOrSchedules.length < 1) {
      return;
    }
    let spotOrSchedules = allSpotsOrSchedules[activeTab];
    if (activeTab < 0) {
      spotOrSchedules = [];
      for (let i = 0; i < allSpotsOrSchedules.length; i++) {
        spotOrSchedules = spotOrSchedules.concat(allSpotsOrSchedules[i]);
      }
    }
    // no need to use updateZoomAndCenter(), since there is map.setFitView
    //tab 滑动自动定位
    spotOrSchedules = spotOrSchedules.map((v, k) => {
      return { lnglat: [v.location.lng + "", v.location.lat + ""], name: v.name, }
    });
    spotOrSchedules.filter((v) => v.pointType != POINT_TYPE.LUNCH)

    //处理path格式
    let paths = [];
    allPath.map((v, k) => {
      let daypath = [];
      v.map((v, k) => {
        daypath.push([v.longitude, v.latitude])
      })
      paths.push(daypath);
    })
    refreshCovering(_map, _amap, spotOrSchedules, paths, activeTab);
  }

  useEffect(() => {
    const asyncF = async () => {
      await _refreshCovering(map, amap);
    }
    asyncF();
  }, [activeTab])

  // 处理API的raw data
  const processPlanData = (plans) => {
    setPlans(plans);
    setBeginDate(plans.beginDate);
    setEndDate(plans.endDate);
    // 滑动条
    let _tabs = [];
    _tabs[0] = '概览';
    for (let i = 0; i < plans.days; i++) {
      _tabs.push(`第${i + 1}天`);
    }
    setTabs(_tabs);

    let _allSpotOrSchedules = [];
    let _allTraffic = [];
    let pathPromises = [];
    let _accommodations = [];
    let accommodationsAddressReqs = [];
    let originExtraSchedules = [];
    let _hotels = [];
    plans.plans.forEach((dayPlan, dayPlanKey) => {
      let temp = geodecodeReq({ lng: dayPlan.accommodation.lng, lat: dayPlan.accommodation.lat });
      // console.log(temp, 'decode');
      accommodationsAddressReqs.push(temp);
      _accommodations.push({
        location: {
          lat: dayPlan.accommodation.lat,
          lng: dayPlan.accommodation.lng
        }
      });
      let _spotOrSchedules = [];
      let _traffic = [];
      dayPlan.edges.forEach((edge, edgeKey) => {
        let pointA = edge.planPoints[0];
        let pointB = edge.planPoints[1];
        if (edgeKey == 0) {
          _spotOrSchedules.push(point2json(pointA));
          if (pointA.pointType == POINT_TYPE.EXTRA_SCHEDULE) {
            originExtraSchedules.push(point2json(pointA));
          }
        }
        if (pointB.pointType === POINT_TYPE.EXTRA_SCHEDULE) {
          originExtraSchedules.push(point2json(pointB));
        }
        _spotOrSchedules.push(point2json(pointB));
      });
      pathPromises.push(pathPromiseReq(_spotOrSchedules));
      _allSpotOrSchedules.push(_spotOrSchedules);
      _allTraffic.push(_traffic);
    });
    // setAccommodations(_accommodations);
    setAllSpotsOrSchedules(_allSpotOrSchedules);
    if (activeTab < 0) {
      //TODO概览
      let spotOrSchedules = [];
      for (let i = 0; i < _allSpotOrSchedules.length; i++) {
        spotOrSchedules = spotOrSchedules.concat(_allSpotOrSchedules[i]);
      }
      updateZoomAndCenter({
        allSpotsOrSchedule_i: spotOrSchedules
      })
    } else {
      updateZoomAndCenter({
        allSpotsOrSchedule_i: _allSpotOrSchedules[activeTab]
      })
    };
    setAllTraffic(_allTraffic);
    Promise.all(accommodationsAddressReqs).then(res => {
      // log.trace(res, 'accommodationsAddressReqs res');
      res.forEach((v, k) => {
        if (v.st) {
          console.log('res.st不为0', 'tourEditting-accommodation 获取地点信息');
          return;
        }
        // console.log(v,'v');
        const formattedAddress = v.data.regeocode.formattedAddress;
        const district = v.data.regeocode.addressComponent.district;
        _accommodations[k] = {
          name: formattedAddress.substr(formattedAddress.indexOf(district) + district.length),
          address: v.data.regeocode.formattedAddress,
          location: {
            lat: _accommodations[k].location.lat,
            lng: _accommodations[k].location.lng
          }
        };
        _hotels.push({});
      });

      setAccommodations(_accommodations);
      setHotels(_hotels);


    }).catch(err => {
      console.log(err, 'tourEditting-processPlanData accommodationsAddressReqs');
      // console.log(err, 'tourEditting-processPlanData accommodationsAddressReqs');
    });
    Promise.all(pathPromises).then(_allPathRes => {
      // log.trace(_allPathRes.length, 'all_path res');
      setAllPath(_allPathRes.map((v, k) => v.path));
      setAllDistance(_allPathRes.map((v, k) => v.distance));
      // log.trace(_allPathRes.map(v=>v.distance), 'all_path distance res');
    }).catch(err => {
      console.log(err, 'tourEditting-setAllPath');
    });
  };

  const updateZoomAndCenter = ({ allSpotsOrSchedule_i }) => {
    const mapWidth = pxToDp(390);
    const mapHeight = pxToDp(320);
    let areaRange = {
      maxLng: 0,
      minLng: 200,
      maxLat: 0,
      minLat: 90,
    };
    allSpotsOrSchedule_i.forEach(v => {
      const { lat, lng } = v.location;
      if (lat === -1 || lng === -1 || lat === 0 || lng === 0) {
        return;
      }
      if (lat > areaRange.maxLat) {
        areaRange.maxLat = lat;
      }
      if (lat < areaRange.minLat) {
        areaRange.minLat = lat;
      }
      if (lng > areaRange.maxLng) {
        areaRange.maxLng = lng;
      }
      if (lng < areaRange.minLng) {
        areaRange.minLng = lng;
      }
    });
    // 点的方形框对角线长度
    // 试出来的60000
    const diagonalLength = 100000 * Math.sqrt(Math.pow(areaRange.maxLat - areaRange.minLat, 2) + Math.pow(areaRange.maxLng - areaRange.minLng, 2));
    // mapsize的对角线长度
    const mapDiagonalLength = Math.sqrt(Math.pow(mapWidth, 2) + Math.pow(mapHeight, 2));
    const scale = diagonalLength / mapDiagonalLength;
    let _zoom;
    // console.log(diagonalLength,mapDiagonalLength, 'updatezoom' )
    if (scale > 0) {
      _zoom = 16 - Math.ceil(Math.log(scale) / Math.log(2));
    } else {
      _zoom = 11;
    }
    setZoom(_zoom);
    setMapCenter([(areaRange.maxLng + areaRange.minLng) / 2, (areaRange.maxLat + areaRange.minLat) / 2]);
  };

  const pathPromiseReq = async (_spotOrSchedules) => {
    let pathRequests = [];
    if (_spotOrSchedules.length >= 2) {
      let prevPoint = null;
      let start = _spotOrSchedules.length;
      for (let i = 0; i < _spotOrSchedules.length; i++) {
        if (_spotOrSchedules[i].pointType == POINT_TYPE.LUNCH) {
          continue;
        } else {
          start = i + 1;
          prevPoint = _spotOrSchedules[i];
          break;
        }
      }

      for (let i = start; i < _spotOrSchedules.length; i++) {
        let curPoint = _spotOrSchedules[i];
        if (curPoint.pointType == POINT_TYPE.LUNCH) {
          continue;
        } else {
          let temp = planPathReq({
            source: `${prevPoint.location.lng},${prevPoint.location.lat}`,
            destination: `${curPoint.location.lng},${curPoint.location.lat}`
          })
          pathRequests.push(temp);
          prevPoint = curPoint;
        }
      }
    }
    return Promise.all(pathRequests).then(values => {
      let _path = [];
      let distance = 0;
      values.forEach(res => {
        if (res.st) {
          console.log('res.st!=0', 'tourEditting-path request error 地图画路线');
          return;
        }
        res.data.steps?.forEach(step => {
          distance += parseFloat(step.distance);
          step.polyline.split(';').forEach(v => {
            let _location = v.split(',');
            _path.push({
              longitude: parseFloat(_location[0]),
              latitude: parseFloat(_location[1])
            });
          });
        });
      });
      return {
        path: _path,
        distance: distance
      };
    }).catch(err => {
      console.log(err, 'tourEditting-path request error 地图画路线这里');
    });
  };

  const RenderTab = () => {
    const renderTabItem = useCallback((activeTab, tab, index, onPressAction) => {
      return ( //概览增加 =>index - 1
        <button key={index} id={tab} onClick={() => { onPressAction(index - 1); }} style={{ backgroundColor: 'transparent' }}>
          <div style={{
            height: pxToDp(30),
            paddingLeft: pxToDp(24), paddingRight: pxToDp(24), whiteSpace: 'nowrap',
            paddingTop: pxToDp(4), paddingBottom: pxToDp(4),
            borderRadius: pxToDp(6), display: 'flex', flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center', backgroundColor: activeTab == index - 1 ? 'white' : 'transparent',
          }}>
            <h1 style={{ fontSize: pxToDp(16), fontWeight: activeTab == index - 1 ? '500' : '400', color: activeTab == index - 1 ? '#333333' : '#949494' }}>{tab}</h1>
          </div>
        </button>
      );
    }, [activeTab]);
    return (
      <div className='tabbar-container' style={{
        flexDirection: 'row', display: 'flex', scrollbarWidth: 0, marginTop: pxToDp(12),
        borderRadius: pxToDp(8), width: '100%', padding: pxToDp(3) + 'px ' + pxToDp(1.5) + 'px', backgroundColor: '#F1F2F6',
      }}>
        {tabs.map((tab, index) =>
          <div key={index} >{renderTabItem(activeTab, tab, index, setActiveTab)}</div>)}
      </div>
    );

  }

  const RenderList = () => {
    if (activeTab == -1) {//概览列表
      // console.log(allSpotsOrSchedules, 'allSpotsOrSchedules')
      return (
        <div >
          {allSpotsOrSchedules.map((v, index) => <RenderOverviewCard key={index} index={index} item={v} time={moment.unix(beginDate)} />)}
        </div>
      );
    }
    return (
      <div>
        {allSpotsOrSchedules[activeTab].map((v, index) => <RenderItem index={index} item={v} key={index} />)}
        <RenderHotel accommodations={accommodations} hotels={hotels} index={activeTab} />
      </div>
    );
  }

  const RenderOverviewCard = ({ index, item, time }) => {
    let set = [];
    item.map((v, k) => set.push(v.name))
    const LAST = set.length - 1;
    const showtime = moment(time).add(index, 'd');
    return (
      <div style={{
        flexDirection: 'row', display: 'flex', backgroundColor: 'white', paddingTop: pxToDp(16), paddingBottom: pxToDp(16),
        paddingLeft: pxToDp(16), paddingRight: pxToDp(16), borderRadius: pxToDp(12), width: '100%', boxSizing: 'border-box',
        marginBottom: pxToDp(10)
      }} onClick={() => { setActiveTab(index) }}>
        <div style={{ flexDirection: 'column', display: 'flex', }}>
          <h1 style={{ fontSize: pxToDp(14), fontWeight: '400', color: '#333333' }}>第{index + 1}天</h1>
          <h1 style={{ fontSize: pxToDp(12), fontWeight: '400', color: '#949494' }}>{showtime.format('MM.DD')}</h1>
        </div>
        <div style={{ flexDirection: 'column', display: 'flex', marginLeft: pxToDp(12), marginLeft: pxToDp(24), alignItems: 'flex-start', }}>
          {set.map((v, k) => {
            return (
              <div key={k} style={{ flexDirection: 'row', display: 'flex' }}>
                <div style={{
                  backgroundColor: colors.primarypurple_07, width: pxToDp(8), marginRight: pxToDp(12), display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'relative'
                }}>
                  {k == 0 ? <div style={{ backgroundColor: 'white', width: pxToDp(8), height: '50%', position: 'absolute', top: 0 }}></div> : <></>}
                  {k == LAST ? <div style={{ backgroundColor: 'white', width: pxToDp(8), height: '50%', position: 'absolute', bottom: 0 }}></div> : <></>}
                  <div style={{
                    width: pxToDp(8), height: pxToDp(8), borderRadius: pxToDp(4), backgroundColor: colors.primarypurple_03, zIndex: 1
                  }} />
                </div>
                <div style={{
                  flexDirection: 'row', display: 'flex',
                  marginTop: k == 0 ? 0 : pxToDp(5), marginBottom: k == LAST ? 0 : pxToDp(5), paddingLeft: pxToDp(16), paddingRight: pxToDp(16), paddingTop: pxToDp(4),
                  paddingBottom: pxToDp(4), borderRadius: pxToDp(20), backgroundColor: '#F5F6FA', justifyContent: 'center'
                }}>
                  <h1 style={{ fontSize: pxToDp(14), fontWeight: '400', color: '#333333' }}>{v}</h1>
                </div>

              </div>
            );
          })}
        </div>
      </div>
    );
  }

  const RenderItem = ({ item, index }) => {
    const { pointType, name, beginTime, endTime, playTime, id, img, address, addressName, note } = item;
    // 还有下一个item；
    // 下一个item不是没选用餐地点的lunch item;
    // 当前item不是没选用餐地点的lunch item;
    const showTransfer = allSpotsOrSchedules[activeTab][index + 1] &&
      !(allSpotsOrSchedules[activeTab][index + 1].pointType === POINT_TYPE.LUNCH &&
        allSpotsOrSchedules[activeTab][index + 1].location.lat === -1) &&
      !(pointType === POINT_TYPE.LUNCH && item.location.lat === -1);
    if (pointType === POINT_TYPE.LUNCH) {
      return (
        <RenderEat nonempty={addressName} index={index} item={item} />
      );
    }
    return (
      <>
        <div style={{
          flexDirection: 'row', display: 'flex', backgroundColor: 'white', paddingTop: pxToDp(12), paddingBottom: pxToDp(12),
          paddingLeft: pxToDp(10), paddingRight: pxToDp(12), borderRadius: pxToDp(12), width: '100%', boxSizing: 'border-box',
          marginBottom: pxToDp(10)
        }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {planpageIcon.purplecircle}
          </div>
          <div style={{ flexDirection: 'column', display: 'flex', marginLeft: pxToDp(12) }}>
            <h1 style={{ fontSize: pxToDp(16), fontWeight: '500', color: '#333333' }}>{name}</h1>
            <h1 style={{ fontSize: pxToDp(14), fontWeight: '400', color: '#949494' }}>{addressName}</h1>
            <div style={{
              borderRadius: pxToDp(6), paddingTop: pxToDp(2), paddingBottom: pxToDp(2), paddingLeft: pxToDp(10), paddingRight: pxToDp(10), backgroundColor: '#F2F0FF', marginTop: pxToDp(6)
              , width: 'max-content'
            }}>
              {(playTime || beginTime.unix() == endTime.unix()) ? <h1 style={{ fontSize: pxToDp(14), fontWeight: '400', color: '#8069F0', }}>计划游玩{secondSpan2Str(playTime)}</h1>
                : <h1 style={{ fontSize: pxToDp(14), fontWeight: '400', color: '#8069F0', }}>{beginTime.format('H:mm')} - {endTime.format('H:mm')}</h1>}
            </div>
            {note ? <div style={{
              borderRadius: pxToDp(6), paddingTop: pxToDp(2), paddingBottom: pxToDp(2), paddingLeft: pxToDp(10), paddingRight: pxToDp(10), backgroundColor: '#F2F0FF', marginTop: pxToDp(6)
              , width: 'max-content'
            }}>
              <h1 style={{ fontSize: pxToDp(14), fontWeight: '400', color: '#333333' }}>{note}</h1></div> : <></>}
          </div>
        </div>
        {index > allTraffic[activeTab].length - 1 ? null :
          <RenderTrans duration={allTraffic[activeTab][index].duration} trafficType={allTraffic[activeTab][index].type} />
        }</>
    );
  }

  let spotOrSchedules = [];
  if (activeTab == -1) {
    for (let i = 0; i < allSpotsOrSchedules.length; i++) {
      spotOrSchedules = spotOrSchedules.concat(allSpotsOrSchedules[i]);
    }
  } else {
    spotOrSchedules = allSpotsOrSchedules[activeTab];
  }

  return !isLoading ? (
    <div className="PlanPage">

      <div style={{ height: pxToDp(8 + 32 + 8) }} />

      {/* <GrabberView
        minimumHeight={pxToDp(60)}
        middleHeight={pxToDp(180)}
        maximumHeight={pxToDp(320)}//加12：弥补导航栏圆角上移
        renderUnderlayHeader={RenderMap}
        onPanResponderRelease={() => updateZoomAndCenter({
          mapHeight: mapSize.height,
          mapWidth: mapSize.width,
          allSpotsOrSchedule_i: spotOrSchedules
        })}
      // ref={grabberRef}
      > */}
      <div style={{ width: '100%', flex: 1 }}>
        <div style={{ width: '100%', height: pxToDp(332) }}>
          <div id="sl-amap-container" style={{
            width: '100%', height: '100%', backgroundColor: 'white',
            // zIndex: -1, pointerEvents: 'auto'
          }}>
          </div>
        </div>

        <div style={{
          backgroundColor: 'white', display: 'flex', flexDirection: 'column', paddingBottom: pxToDp(8),
          paddingLeft: pxToDp(16), paddingRight: pxToDp(16), paddingTop: pxToDp(8)
        }}>
          <h2 style={{ color: '#333333', }}>{plans?.name}</h2>
          <RenderTab />
        </div>

        <div style={{
          width: '100%', flex: 1, paddingBottom: pxToDp(100), paddingLeft: pxToDp(12),
          paddingRight: pxToDp(12), backgroundColor: colors.gray_tsp_07, paddingTop: pxToDp(10)
        }}>
          <RenderList />
        </div>
      </div>
      {/* </GrabberView> */}

      {/* top header */}
      <div style={{
        position: 'fixed', padding: pxToDp(8) + 'px ' + pxToDp(12) + 'px', width: '100%',
        backgroundColor: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center'
      }}>
        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <img src={require('../../assets/logo.png')} width={pxToDp(32)} height={pxToDp(32)} />
          <h1 style={{ fontSize: pxToDp(14), fontWeight: '500', color: 'balck', marginLeft: pxToDp(8) }}>随鹿旅行App</h1>
        </div>
        <div style={{
          padding: pxToDp(6) + 'px ' + pxToDp(10) + 'px', flexDirection: 'row',
          backgroundColor: colors.primarypurple_03, borderRadius: pxToDp(8), display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}
          onClick={() => {
            if (isWeChat()) {
              window.location.href = OFFICIAL_LINK;//如果超时就跳转到app下载页
            } else if (isIOS()) {
              window.location.href = IOS_LINK;//如果超时就跳转到首页
            } else if (isAndroid()) {
              window.location.href = ANDROID_LINK;//如果超时就跳转到app下载页
            } else {
              window.location.href = OFFICIAL_LINK;
            }
          }}>
          <h1 style={{ ...fonts.caption1, color: 'white' }}>下载App</h1>
        </div>
      </div>

      <div style={{
        position: 'fixed', padding: pxToDp(8) + 'px ' + pxToDp(16) + 'px',
        flexDirection: 'row', alignSelf: 'center', bottom: pxToDp(82),
        backgroundColor: colors.primarypurple_03, borderRadius: pxToDp(8), display: 'flex', justifyContent: 'center', alignItems: 'center',
        boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.20)'
      }}
        onClick={() => {
          var id = String(window.location.search.replace('?planid=', ''));
          if (window.isSecureContext && navigator.clipboard) {
            window.navigator.clipboard.writeText('soroute' + id)
          }
          if (isWeChat()) {
            window.location.href = OFFICIAL_LINK;//如果超时就跳转到app下载页
          } else if (isIOS()) {
            window.location = APP_SCHEME + '/' + plans.planId + '/' + plans.name;//打开某手机上的某个app应用
            setTimeout(function () {
              window.location.href = IOS_LINK;//如果超时就跳转到首页
            }, 10000);
          } else if (isAndroid()) {
            window.location = APP_SCHEME + '/' + plans.planId + '/' + plans.name;//打开某手机上的某个app应用
            setTimeout(function () {
              window.location.href = ANDROID_LINK;//如果超时就跳转到app下载页
            }, 10000);
          } else {
            window.location.href = OFFICIAL_LINK;
          }
        }}>
        <h1 style={{ fontSize: pxToDp(14), fontWeight: '500', color: 'white' }}>在随鹿App内打开</h1>
      </div>
    </div>
  ) : <div style={{ height: '95vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Digital size={40} color={colors.primarypurple_03} animating={true} />
  </div>;

}

