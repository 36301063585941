import moment from 'src/utils/moment';

export var refreshCovering = (function (map, AMap, points, paths, activeTab) {
    // static varibale
    var marker = null;
    return async function (map, AMap, points, paths, activeTab) {
        const gridSize = 60;
        // 数据中需包含经纬度信息字段 lnglat
        const _renderClusterMarker = function (context) {
            var div = document.createElement('div');
            div.style.backgroundColor = '#8069F0';
            var size = Math.round(33);
            div.style.width = div.style.height = size + 'px';
            div.style.border = 'solid 2px ' + '#C5BAFA';
            div.style.borderRadius = size / 2 + 'px';
            div.style.boxShadow = '0 0 5px ' + 'rgba(0, 0, 0, 0.14)';
            div.innerHTML = context.count;
            div.style.lineHeight = size + 'px';
            div.style.color = '#FFFFFF';
            div.style.fontSize = '14px';
            div.style.textAlign = 'center';
            context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2));
            context.marker.setContent(div)
        };
        const _renderMarker = function (context) {
            var content =
                `<div style="width:max-content;margin-left:-50%;margin-top:-30px;display: flex;align-items: center;flex-direction: column;">
            <div style="width: 100%; background-color: #ffffff; 
              padding: 4px 8px 4px 8px; border-radius: 8px; box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.06); 
              justify-content: center; align-items: center;">
              <h1 style="font-size: 14px; font-style: normal; font-weight: 500;color: #333333;">${context.data[0].name}</h1>
            </div>
            <div style="width: 100%;display: flex;justify-content: center;flex-direction: row;" >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="6" viewBox="0 0 24 6" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 0.000899746C1.33803 0.000899746 2.17581 -0.0030238 3.01384 0.00551568C3.81591 0.0138244 4.71362 0.025595 5.49416 0.303243C6.33733 0.603279 6.89552 1.12788 7.45714 1.74526C7.86148 2.18931 8.65572 3.13674 9.04245 3.59417C9.35897 3.96922 9.97856 4.71054 10.3169 5.0692C10.7427 5.5204 11.2691 6 12.0002 6C12.7314 6 13.2575 5.5204 13.6831 5.06966C14.0214 4.71123 14.641 3.96945 14.9578 3.59464C15.344 3.1372 16.1383 2.18978 16.5429 1.74572C17.1055 1.12834 17.6627 0.603741 18.5056 0.303705C19.2864 0.026749 20.1843 0.014286 20.9859 0.00597727C21.8242 -0.00256221 22.662 0.00136134 23.5 0.00136134" fill="white"/>
          </svg>
            </div>
            <div style="width: 100%;display: flex;justify-content: center;flex-direction: row;" >
              <div style="background-color: #8069F0; height: 18px; width: 18px; border: 2px solid #C5BAFA; border-radius: 12px; box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 3px;margin-top:4px" />
            </div>
            </div>`;
            var offset = new AMap.Pixel(-9, -9);
            context.marker.setContent(content)
            context.marker.setOffset(offset)
        };
        // clear marker, which cannot be cleared by clearMap()
        if (marker) {
            marker.setMap(null);
        }
        await map.clearMap();
        // add cluster marker
        marker = new AMap.MarkerCluster(map, points, {
            gridSize: gridSize, // 设置网格像素大小
            renderClusterMarker: _renderClusterMarker, // 自定义聚合点样式
            renderMarker: _renderMarker, // 自定义非聚合点样式
        })
        // 折线-----
        let lineArray = [];
        if (activeTab < 0) {
            paths.map((v, k) => {
                let polyline = new AMap.Polyline({
                    path: v,
                    strokeWeight: 6,
                    borderWeight: 2, //线条宽度，默认为1
                    strokeColor: "#907AFF", //线条颜色
                    lineJoin: "round", //折线拐点连接处样式
                    strokeOpacity: 1,
                });
                lineArray.push(polyline);
            })
        }
        else {
            var polyline = new AMap.Polyline({
                path: paths[activeTab],
                strokeWeight: 6,
                borderWeight: 2, //线条宽度，默认为1
                strokeColor: "#907AFF", //线条颜色
                lineJoin: "round", //折线拐点连接处样式
                strokeOpacity: 1,
                // showDir:true // 线段箭头
            })
            lineArray.push(polyline);
        }
        await map.add(lineArray);
        // 折线-----      

        await map.setFitView(lineArray);
    }
})();

export const point2json = (point) => {
    return {
        id: point.id,
        pointId: point.pointId,
        name: point.pointName,
        pointType: point.pointType,
        beginTime: moment.unix(point.beginTime),
        endTime: moment.unix(point.endTime),
        playTime: point.playTime,
        location: {
            lat: point.lat,
            lng: point.lng
        },
        note: point.note,
        img: point.img,
        address: point.address,
        addressName: point.addressName,
    }
} 