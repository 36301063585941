// definition
// for map
export const POINT_TYPE = {
    SPOT: 0,
    EXTRA_SCHEDULE: 1,
    LUNCH: 2
};

// for map
export const TRAFFIC_TYPE = {
    WALK: 0,
    PUBLIC: 1,
    DRIVING: 2
};

// link of official websit
export const OFFICIAL_LINK = 'https://soroute.cn/';
export const APP_SCHEME = 'sorouteapp://soooroute';
// link to download 
export const IOS_LINK = 'https://apps.apple.com/app/%E9%9A%8F%E9%B9%BF%E6%97%85%E8%A1%8C/id6479046744';
export const ANDROID_LINK = 'https://a.app.qq.com/o/simple.jsp?pkgname=soroute.mobile';