import request from '../utils/request';

export function getPlan(planId) {
    return request({
        url: `/share_api/plan/get_plan?plan_id=${planId}`,
        method: 'get',
    })
}
export const planPathReq = ({ source, destination }) => {
    return request({
        url: 'share_api/plan/plan_path',
        method: 'post',
        data: {
            source: source,
            destination: destination
        }
    })
};

/**
 * 
 * @param {String} address 地址 (北京市朝阳区阜通东大街6号)
 * @returns 
 */
export const geodecodeReq = ({ lng, lat }) => {
    const data = {
        lng,
        lat,
    };
    return request({
        url: 'share_api/plan/decode',
        method: 'post',
        data: {
            lng: lng,
            lat: lat,
        }
    })
};  