import moment from 'moment';
moment.locale('zh-cn');
export default moment;

// convert a time span in seconds to string
// e.g. 3660 -> '1小时1分钟'
export function secondSpan2Str(span){
    let hours = Math.floor(span / 3600);
    let minutes = Math.floor(span % 3600 / 60);
    let str = '';
    if(hours > 0){
        str += `${hours}小时`;
    }
    if(minutes > 0){
        str += `${minutes}分钟`;
    }
    if(str === ''){
        str = '0分钟';
    }
    return str;
}