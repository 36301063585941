import { pxToDp, colors, fonts } from 'src/utils/styleKits';
import { POINT_TYPE, TRAFFIC_TYPE } from 'src/utils/defs';
import { planpageIcon } from 'src/utils/svg';

export const RenderHotel = ({ hotels, accommodations, index }) => {
    return (
        <div style={{
            flexDirection: 'row', display: 'flex', backgroundColor: 'white', paddingTop: pxToDp(12), paddingBottom: pxToDp(12)
            , paddingLeft: pxToDp(10), paddingRight: pxToDp(12), borderRadius: pxToDp(12), width: '100%', boxSizing: 'border-box', marginBottom: pxToDp(10)
        }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {planpageIcon.purplecircle}
            </div>
            <div style={{ flexDirection: 'column', display: 'flex', marginLeft: pxToDp(12) }}>
                <div style={{ flexDirection: 'row', display: 'flex', }}>
                    <div style={{ width: pxToDp(16), height: pxToDp(16), borderRadius: pxToDp(4), backgroundColor: '#907AFF', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {planpageIcon.hotelIcon}</div>
                    <h1 style={{ fontSize: pxToDp(14), fontWeight: '500', color: '#333333', marginLeft: pxToDp(4) }}>住宿</h1>
                </div>
                <h1 style={{ fontSize: pxToDp(16), fontWeight: '500', color: '#333333', marginTop: pxToDp(4) }}>
                    {hotels?.[index]?.name ? hotels?.[index].name :
                        accommodations?.[index]?.name ? accommodations?.[index]?.name + '附近' : '暂无推荐范围'}
                </h1>
            </div>
        </div>
    );
}

export const RenderEat = ({ nonempty, item, index }) => {
    const { name, addressName } = item;
    return (
        <div style={nonempty ? {
            flexDirection: 'row', display: 'flex', backgroundColor: 'white', paddingTop: pxToDp(12), paddingBottom: pxToDp(12),
            paddingLeft: pxToDp(10), paddingRight: pxToDp(12), borderRadius: pxToDp(12), width: '100%', boxSizing: 'border-box', marginBottom: pxToDp(10)
        } : {
            flexDirection: 'row', display: 'flex', paddingLeft: pxToDp(10), paddingRight: pxToDp(10), paddingTop: pxToDp(4), paddingBottom: pxToDp(4),
            marginBottom: pxToDp(10), borderRadius: pxToDp(8), backgroundColor: 'white', width: 'max-content', alignItems: 'center', height: pxToDp(32)
        }}>
            {nonempty ? planpageIcon.purplecircle : planpageIcon.graycircle}
            <div style={{ flexDirection: 'row', display: 'flex', marginLeft: pxToDp(16) }}>
                {planpageIcon.eatIcon}
                <h1 style={{ fontSize: pxToDp(14), fontWeight: '500', color: '#333333', marginLeft: pxToDp(4) }}>用餐</h1>
                {nonempty ?
                    <><h1 style={{ fontSize: pxToDp(16), fontWeight: '500', color: '#333333', marginTop: pxToDp(2) }}>{name}</h1>
                        <h1 style={{ fontSize: pxToDp(14), fontWeight: '400', color: '#949494' }}>{addressName}</h1></>
                    : <></>}
            </div>
        </div>
    );
}

export const RenderTrans = ({ duration, trafficType }) => {
    // console.log(duration, trafficType,'traffic')
    const trafficName = trafficType === TRAFFIC_TYPE.WALK ? '步行' :
        trafficType === TRAFFIC_TYPE.PUBLIC ? '公交地铁' :
            trafficType === TRAFFIC_TYPE.DRIVING ? '驾车' : '暂不确定';
    const icon = trafficType === TRAFFIC_TYPE.WALK ? planpageIcon.walkIcon :
        trafficType === TRAFFIC_TYPE.PUBLIC ? planpageIcon.busIcon :
            trafficType === TRAFFIC_TYPE.DRIVING ? planpageIcon.driveIcon : planpageIcon.busIcon;
    return (
        <div style={{
            flexDirection: 'row', display: 'flex', paddingLeft: pxToDp(10), paddingRight: pxToDp(10), paddingTop: pxToDp(4), paddingBottom: pxToDp(4),
            marginBottom: pxToDp(10), borderRadius: pxToDp(8), backgroundColor: 'white', width: '100%', alignItems: 'center', boxSizing: 'border-box', height: pxToDp(34)
        }}>
            {planpageIcon.purplecircle}
            <div style={{ flexDirection: 'row', display: 'flex', marginLeft: pxToDp(16), paddingTop: pxToDp(2), paddingBottom: pxToDp(2) }}>
                <div style={{ width: pxToDp(16), height: pxToDp(16), }}>{icon}</div>
                <h1 style={{ fontSize: pxToDp(14), fontWeight: '500', color: '#333333', marginLeft: pxToDp(4) }}>{duration == 0 ? '暂不确定' : (trafficName + parseInt(duration / 60) + '分钟')}</h1>
            </div>
            <h1 style={{ fontSize: pxToDp(14), fontWeight: '400', color: '#949494', marginLeft: pxToDp(4) }}></h1>
        </div>
    );
}