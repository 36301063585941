
export function isWeChat() {
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase();
    // console.log(ua.match(/MicroMessenger/i))
    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if (ua.match(/MicroMessenger/i)) {
        return true;
    } else {
        return false;
    }
}


export function isIOS() {
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase();
    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    // console.log(ua.match(/(iPhone|iPod|iPad);?/i))
    if (ua.match(/(iPhone|iPod|iPad);?/i)) {
        return true;
    } else {
        return false;
    }
}

export function isAndroid() {
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase();
    // console.log(ua.match(/android/i))
    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if (ua.match(/android/i)) {
        return true;
    } else {
        return false;
    }
}